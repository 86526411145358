@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Merriweather+Sans:wght@400;700&display=swap");
body {
  font-family: 'Lato', sans-serif;
  color: #040000;
}

.layout_padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

.layout_padding2 {
  padding: 45px 0;
}

.layout_padding2-top {
  padding-top: 45px;
}

.layout_padding2-bottom {
  padding-bottom: 45px;
}

.layout_padding-top {
  padding-top: 120px;
}

.layout_padding-bottom {
  padding-bottom: 120px;
}

.heading_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.heading_container h2 {
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-transform: uppercase;
  font-family: 'Merriweather Sans', sans-serif;
}

.heading_container p {
  margin-bottom: 0;
}

.heading_container.heading_center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

h1,
h2 {
  font-family: 'Merriweather Sans', sans-serif;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

/*header section*/
.hero_area {
  position: relative;
  background-color: #d1e3ff;
}

.header_section .container-fluid {
  padding-right: 25px;
  padding-left: 25px;
}

.header_section .header_top {
  padding: 15px 0;
  background-color: #000000;
}

.header_section .header_top .contact_nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.header_section .header_top .contact_nav a {
  color: #ffffff;
}

.header_section .header_top .contact_nav a i {
  color: #ff8a1d;
}

.header_section .header_bottom {
  padding: 10px 0;
}

.navbar-brand {
  padding: 0;
  margin: 0;
  color: #000000;
  font-weight: bold;
  font-size: 24px;
  font-weight: bold;
}

.navbar-brand span {
  color: #0355cc;
  text-transform: uppercase;
}

.custom_nav-container {
  padding: 0;
}

.custom_nav-container .navbar-nav {
  margin-left: auto;
}

.custom_nav-container .navbar-nav .nav-item .nav-link {
  padding: 10px 25px;
  color: #000000;
  text-align: center;
}

.custom_nav-container .navbar-nav .nav-item:hover .nav-link, .custom_nav-container .navbar-nav .nav-item.active .nav-link {
  color: #0355cc;
}

.custom_nav-container .form-inline .nav_search-btn {
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
  color: #000000;
}

.custom_nav-container .form-inline .nav_search-btn:hover {
  color: #0355cc;
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler {
  padding: 0;
  width: 37px;
  height: 42px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.custom_nav-container .navbar-toggler span {
  display: block;
  width: 35px;
  height: 4px;
  background-color: #000000;
  margin: 7px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  border-radius: 5px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.custom_nav-container .navbar-toggler span::before, .custom_nav-container .navbar-toggler span::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000000;
  top: -10px;
  border-radius: 5px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.custom_nav-container .navbar-toggler span::after {
  top: 10px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span::before, .custom_nav-container .navbar-toggler[aria-expanded="true"] span::after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  top: 0;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-1 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  margin: 0;
  margin-bottom: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-2 {
  display: none;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-3 {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  margin: 0;
  margin-top: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-1,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-2,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-3 {
  -webkit-transform: none;
          transform: none;
}

.quote_btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.quote_btn-container a {
  color: #ffffff;
  margin-right: 25px;
  text-transform: uppercase;
}

.quote_btn-container a span {
  margin-left: 5px;
}

.quote_btn-container a:hover {
  color: #ff8a1d;
}

/*end header section*/
/* slider section */
.slider_section {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-size: cover;
  background-position: bottom;
}

.slider_section .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.slider_section #customCarousel1 {
  width: 100%;
  position: unset;
}

.slider_section .detail-box {
  padding-bottom: 90px;
}

.slider_section .detail-box h1 {
  font-weight: bold;
  margin-bottom: 25px;
  color: #0355cc;
}

.slider_section .detail-box p {
  color: #444;
  font-size: 15px;
}

.slider_section .detail-box a {
  display: inline-block;
  font-family: 'Merriweather Sans', sans-serif;
  text-transform: uppercase;
  padding: 10px 45px;
  background-color: #ff8a1d;
  border: 1px solid #ff8a1d;
  border-radius: 5px;
  color: #ffffff;
  margin-top: 15px;
}

.slider_section .detail-box a:hover {
  background-color: transparent;
  color: #ff8a1d;
}

.slider_section .img-box img {
  width: 100%;
}

.feature_section {
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.feature_section .feature_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.feature_section .feature_container .box {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  margin: 0 10px;
  padding: 45px 15px;
  background-color: #ffffff;
  color: #555089;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
}

.feature_section .feature_container .box .img-box {
  width: 90px;
  height: 90px;
}

.feature_section .feature_container .box .img-box svg {
  width: 100%;
  height: auto;
  max-height: 100%;
  fill: #726dae;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.feature_section .feature_container .box .img-box svg path {
  fill: #726dae;
}

.feature_section .feature_container .box .name {
  margin-top: 20px;
  text-transform: uppercase;
  font-family: 'Merriweather Sans', sans-serif;
  margin-bottom: 0;
}

.feature_section .feature_container .box:hover, .feature_section .feature_container .box.active {
  background-color: #ff8a1d;
  color: #ffffff;
}

.feature_section .feature_container .box:hover .img-box svg, .feature_section .feature_container .box.active .img-box svg {
  fill: #ffffff;
}

.feature_section .feature_container .box:hover .img-box svg path, .feature_section .feature_container .box.active .img-box svg path {
  fill: #ffffff;
}

.about_section .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.about_section .detail-box h2 {
  text-transform: uppercase;
  font-weight: bold;
}

.about_section .detail-box p {
  margin-top: 10px;
}

.about_section .detail-box a {
  margin-top: 15px;
  display: inline-block;
  font-family: 'Merriweather Sans', sans-serif;
  text-transform: uppercase;
  padding: 10px 45px;
  background-color: #0355cc;
  border: 1px solid #0355cc;
  border-radius: 2px;
  color: #ffffff;
}

.about_section .detail-box a:hover {
  background-color: transparent;
  color: #0355cc;
}

.about_section .img-box img {
  width: 100%;
}

.professional_section {
  background-color: #d1e3ff;
}

.professional_section .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.professional_section .img-box img {
  width: 100%;
}

.professional_section .detail-box h2 {
  text-transform: uppercase;
  font-weight: bold;
  color: #0355cc;
}

.professional_section .detail-box p {
  margin-top: 20px;
}

.professional_section .detail-box a {
  margin-top: 25px;
  display: inline-block;
  font-family: 'Merriweather Sans', sans-serif;
  text-transform: uppercase;
  padding: 10px 45px;
  background-color: #ff8a1d;
  border: 1px solid #ff8a1d;
  border-radius: 2px;
  color: #ffffff;
}

.professional_section .detail-box a:hover {
  background-color: transparent;
  color: #ff8a1d;
}

.service_section {
  position: relative;
}

.service_section .box {
  margin-top: 30px;
  text-align: center;
  -webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
  padding: 25px 15px;
  -webkit-transition: all .3s;
  transition: all .3s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.service_section .box .img-box {
  width: 65px;
  height: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.service_section .box .img-box img {
  max-height: 100%;
  max-width: 100%;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.service_section .box .detail-box {
  margin-top: 15px;
}

.service_section .box .detail-box h5 {
  font-weight: bold;
}

.service_section .box .detail-box p {
  margin: 0;
}

.service_section .box:hover {
  background-color: #ff8a1d;
  color: #ffffff;
}

.service_section .box:hover .img-box img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.service_section .btn-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 45px;
}

.service_section .btn-box a {
  display: inline-block;
  font-family: 'Merriweather Sans', sans-serif;
  text-transform: uppercase;
  padding: 10px 45px;
  background-color: #0355cc;
  border: 1px solid #0355cc;
  border-radius: 0;
  color: #ffffff;
}

.service_section .btn-box a:hover {
  background-color: transparent;
  color: #0355cc;
}

.client_section .heading_container {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.client_section .box {
  margin: 15px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 25px;
}

.client_section .box .client_id {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.client_section .box .client_id .img-box {
  width: 125px;
  min-width: 125px;
  margin-right: 15px;
}

.client_section .box .client_id .img-box img {
  width: 100%;
  border-radius: 100%;
}

.client_section .box .client_id .client_detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-right: 25px;
}

.client_section .box .client_id .client_detail .client_info h6 {
  margin-bottom: 0;
}

.client_section .box .client_id .client_detail .client_info i {
  color: #fbaf5d;
}

.client_section .box .client_text {
  margin-top: 20px;
}

.client_section .owl-carousel .owl-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 20px;
}

.client_section .owl-carousel .owl-nav button {
  width: 50px;
  height: 50px;
  background-color: #0355cc;
  outline: none;
  margin: 0 20px;
  color: #ffffff;
}

.heading_container {
  margin-bottom: 30px;
}

.contact_section input {
  width: 100%;
  border: none;
  height: 50px;
  margin-bottom: 25px;
  padding-left: 25px;
  background-color: #ffffff;
  outline: none;
  color: #ffffff;
  -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
}

.contact_section input::-webkit-input-placeholder {
  color: #737272;
}

.contact_section input:-ms-input-placeholder {
  color: #737272;
}

.contact_section input::-ms-input-placeholder {
  color: #737272;
}

.contact_section input::placeholder {
  color: #737272;
}

.contact_section input.message-box {
  height: 135px;
}

.contact_section button {
  border: none;
  display: inline-block;
  font-family: 'Merriweather Sans', sans-serif;
  text-transform: uppercase;
  padding: 10px 55px;
  background-color: #0355cc;
  border: 1px solid #0355cc;
  border-radius: 0;
  color: #ffffff;
  margin-top: 15px;
}

.contact_section button:hover {
  background-color: transparent;
  color: #0355cc;
}

.contact_section .map_container {
  height: 360px;
}

.contact_section .map_container .map {
  height: 100%;
}

.info_section {
  padding: 75px 0;
  background-color: #0a0f43;
  text-align: center;
  color: #fff;
}

.info_section h4 {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 25px;
}

.info_section .social-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 25px;
}

.info_section .social-box .box {
  background-color: #ffffff;
  padding: 5px 15px;
}

.info_section a {
  margin: 0 5px;
  color: #0355cc;
}

.info_section a i {
  font-size: 18px;
}

.info_section a:hover {
  color: #ff8a1d;
}

.info_items a {
  position: relative;
}

.info_items .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.info_items .item .img-box {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  line-height: 80px;
  background-color: #0355cc;
  color: #ffffff;
}

.info_items .item .img-box i {
  font-size: 24px;
}

.info_items .item p {
  margin-top: 25px;
  color: #fff;
  margin-bottom: 0;
  background-color: #0a0f43;
}

.info_items .item:hover .img-box {
  background-color: #ff8a1d;
}

.info_items {
  position: relative;
}

.info_items::before {
  content: "";
  position: absolute;
  top: 65px;
  width: 75%;
  height: 1px;
  background-color: #fff;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

/* footer section*/
.footer_section {
  background-color: #0a0f43;
}

.footer_section p {
  margin: 0;
  padding: 25px 0;
  color: #ffffff;
  text-align: center;
  border-top: 0.8px solid #ffffff;
}

.footer_section a {
  color: inherit;
}

.social-box {
  text-align: center; /* Centers the heading and icons */
}

.social-icons {
  display: flex;
  justify-content: center; /* Centers the icons horizontally */
  gap: 20px; /* Adjusts the spacing between icons */
  margin-top: 20px; /* Adds space above the icons */
}

.social-icons a {
  color: #000; /* Sets the color of the icons */
  text-decoration: none; /* Removes underline from links */
}

.social-icons a:hover {
  color: #007bff; /* Changes icon color on hover (optional) */
}
